// src/hooks/useProductFetcher.tsx
import { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { ServiceType } from '../types/types';

export const useServiceFetcher = (firmId: number | null = null, city: string | null = null) => {
    const [services, setServices] = useState<ServiceType[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchServices = async () => {
        try {
            const params: any = {
                page: page,
                include: 'sellers.firm',
                // diğer parametreler...
            };

            if (firmId) {
                params['filter[firm_id]'] = firmId;
            }

            if(city) {
                params['filter[firm.city]'] = city;
            }

            const response = await axiosInstance.get('/ecommerce/services', { params });
            const newServices = response.data.data;
            
            if (page === 1) {
                setServices(newServices);
            } else {
                setServices(prev => [...prev, ...newServices]);
            }
            
            setHasMore(newServices.length > 0);
            setLoading(false);
        } catch (err) {
            setError('Servisler yüklenirken bir hata oluştu');
            setLoading(false);
        }
    };

    const resetServices = () => {
        setServices([]);
        setPage(1);
        setHasMore(true);
        setLoading(true);
    };

    useEffect(() => {
        resetServices();
    }, [firmId]);

    useEffect(() => {
        fetchServices();
    }, [page, firmId]);

    const loadMore = () => {
        setPage(prev => prev + 1);
    };

    return { services, loading, error, hasMore, loadMore, resetServices };
};