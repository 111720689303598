// src/hooks/useProductFetcher.tsx
import { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { useFilter } from '../contexts/FilterContext';
import { ProductType } from '../types/types';

export const useProductFetcher = (city: string | null = null) => {
    const { filters } = useFilter();
    const [products, setProducts] = useState<ProductType[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchProducts = async () => {
        setLoading(true);
        try {

            let params = {
                ...filters,
                include: 'products.firm',
                'filter[hasProducts]': true,
                page,
            };

            if(city) {
                params['filter[products.firm.city]'] = city;
            }

            const response = await axiosInstance.get('/ecommerce/products', { params });

            const newProducts: ProductType[] = response.data.data;
            setHasMore(page < response.data.meta.last_page);
            setProducts((prev) => (page === 1 ? newProducts : [...prev, ...newProducts]));
        } catch (error) {
            setError('Ürünleri çekerken bir hata oluştu.');
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [filters, page]);

    const loadMore = () => setPage((prev) => prev + 1);

    return { products, loading, error, hasMore, loadMore };
};