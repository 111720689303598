// src/pages/Orders.tsx
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// @ts-ignore
import Modal from 'react-modal';
import axiosInstance from '../axiosInstance';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import {useAuthMiddleware} from "../contexts/AuthMiddlewareContext";

interface Order {
    id: number;
    order_number: string;
    total_price: number;
    status: string;
    firm:{
        title: string;
        caption: string;
        address: string;
        city: string;
        county: string;
        phone: string;
    }
    product_count: number;
    products: Product[];
    basket: Basket[];
}

interface Basket {
    id: number;
    product: Product;
    service: {
        name: string;
    }
    quantity: number;
    price: number;
    vatted_price_formatted: string;
}

interface Product {
    id: number;
    title: string;
    quantity: number;
    price: number;
    dot?: string;
    season?: string;
}

const Orders: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [lastPage, setLastPage] = useState(1); // Toplam sayfa sayısı


    const { userData } = useAuthMiddleware();


    useEffect(() => {
        fetchOrders(1);
    }, []);

    const fetchOrders = async (pageNumber: number) => {
        if(loading || !hasMore) return;
        setLoading(true)
        try {
            const response = await axiosInstance.get(
                `/ecommerce/order?reference=${userData?.id}&include=firm,basket.product,basket.service&page=${pageNumber}`
            );
            const { data, meta } = response.data;

            setOrders((prevOrders) => [...prevOrders, ...data]);
            setLastPage(meta.last_page); // API'den toplam sayfa sayısını alıyoruz
            setHasMore(pageNumber < meta.last_page); // Son sayfaya ulaşıldıysa hasMore false olacak
            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setLoading(false);
        }
    };

    const loadMore = () => {
        if (page < lastPage) {
            const nextPage = page + 1;
            setPage(nextPage);
            fetchOrders(nextPage);
        }
    };

    const openModal = (order: Order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    if (loading && orders.length === 0) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50} />
            </div>
        );
    }

    if (!loading && orders.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <img
                    src="https://cdn-icons-png.flaticon.com/512/4076/4076432.png"
                    alt="No Orders"
                    className="w-24 h-24 mb-4"
                />
                <p className="text-lg font-bold text-gray-700">Sipariş bulunamadı</p>
                <button
                    onClick={() => navigate('/')}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition mt-4"
                >
                    Ana Sayfaya Dön
                </button>
            </div>
        );
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-2xl font-bold mb-4">Siparişlerim</h1>
            <InfiniteScroll
                dataLength={orders.length}
                next={loadMore}
                hasMore={hasMore}
                loader={
                    <div className="flex justify-center mt-4">
                        <ClipLoader color="#3498db" size={30} />
                    </div>
                }
                endMessage={
                    <p className="text-center mt-4 text-gray-500">Tüm siparişler yüklendi.</p>
                }
            >
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {orders.map((order) => (
                        <div
                            key={order.id}
                            className="bg-white shadow-md p-4 rounded-md cursor-pointer hover:shadow-lg transition"
                            onClick={() => openModal(order)}
                        >
                            <div className="flex flex-row justify-between">
                                <h2 className="text-lg font-semibold mb-2">Sipariş No: {order.id}</h2>
                                {order.status ? <div className={`p-2 bg-green-500 bg-opacity-10 text-green-500 text-[12px] rounded-[6px]`}>
                                    Tamamlandı
                                </div> : <div className={`p-2 bg-red-500 bg-opacity-10 text-red-500 text-[12px] rounded-[6px]`}>Tamamlanmadı</div>}
                            </div>
                            <p>Toplam Tutar: {order.total_price.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</p>
                            <p>Satıcı: {order.firm.title} - {order.firm.city} {order.firm.county}</p>
                            <p>Ürün Adedi: {order.basket.reduce((total, item) => total + item.quantity, 0)}</p>
                        </div>
                    ))}
                </div>
            </InfiniteScroll>

            {/* Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxHeight: '80%',
                        overflow: 'auto',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
                ariaHideApp={false}
            >
                <button className="absolute top-4 right-4" onClick={closeModal}>
                    X
                </button>
                <h2 className="text-xl font-bold mb-4">Sipariş Detayı</h2>
                <hr/>
                <div className="p-1">
                    <p>
                        <strong>{selectedOrder?.firm?.title}</strong> <br />
                        {selectedOrder?.firm?.address} <br />
                        {selectedOrder?.firm?.city} {selectedOrder?.firm?.county} <br />
                        {selectedOrder?.firm?.phone}
                    </p>
                </div>
                <hr/>
                {selectedOrder && (
                    <div>
                        {selectedOrder.basket.map((product) => (
                            <div
                                key={product.id}
                                className="border-b py-2 flex justify-between items-center"
                            >
                                <div className="flex flex-col">
                                    <strong>{product.product.title}</strong>
                                    <div className={`flex flex-row justify-between`}>
                                        <div>
                                            <div className={`flex flex-row mt-3`}>
                                                <span className={`rounded-[6px] p-2 bg-amber-500 bg-opacity-20 text-amber-500 text-[10px] font-bold mr-2`}>{product.product?.dot}</span>
                                                <span className={`rounded-[6px] p-2 bg-black bg-opacity-20 text-black text-[10px] font-bold mr-2`}>{product.product?.season}</span>
                                                <span className={`rounded-[6px] p-2 bg-blue-500 bg-opacity-20 text-blue-500 text-[10px] font-bold mr-2`}>{product.quantity} Adet</span>
                                            </div>
                                        </div>
                                        <span className={`rounded-[6px] p-2 bg-green-500 bg-opacity-20 text-green-500 text-[10px] font-bold mt-3`}>{product.vatted_price_formatted} TRY</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Orders;